<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="3" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Nǐ jǐ suì? (xià)",
        hanzi: "你几岁? (下)",
        english: "How old are you? (II)",
        sound: require("@/assets/audio/L1/1-Lesson/lesson-21-title.mp3")
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>
