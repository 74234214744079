<!--
 * @Author: your name
 * @Date: 2022-03-15 09:05:33
 * @LastEditTime: 2022-03-15 14:22:40
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson22\10_RecognitionPage_shuzhe.vue
-->
<template>
  <div class="recognition-page-heng-container">
    <RecognitionStrokePage
      :backgroundImage="bgImg"
      :strokeObj="strokeDetail"
    />
  </div>
</template>

<script>
import RecognitionStrokePage from "@/components/Course/CoursePage/RecognitionStrokePage";

export default {
  components: {
    RecognitionStrokePage,
  },
  data() {
    return {
      bgImg: require("@/assets/img/15-Hanzi-strokes/lesson-22-shuzhe-image.svg"),
      strokeDetail: {
        pinName: "shùzhé",
        chineseName: "竖折",
        strokeImg: require("@/assets/img/15-Hanzi-strokes/lesson-22-shuzhe-red-border.svg"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-heng-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
